export enum SectionIds {
  Home = 'home',
  About = 'about',
  OurPartners = 'our-partners',
  FAQ = 'faq',
  Contact = 'contact',
}

export enum BenefitIds {
  Benefit1 = 'benefit-1',
  Benefit2 = 'benefit-2',
  Benefit3 = 'benefit-3',
  Benefit4 = 'benefit-4',
}
